import React, { useState, useEffect } from "react"
import { useLocation } from "@reach/router"
import { Cart, CartEmitter } from "../"
import { AnimatedSection, AnimatedLink } from "@components/shared"
import { Auth } from "@components/theme"
import logoY from "../../../images/logo-yellow.png"
import {
  app__footer__section,
  app__footer,
  app__footer__logo,
  app__footer__list,
  app__footer__list__item,
  app__footer__icons,
  app__footer__media__icon,
  app__footer__copyFoot,
} from "./footer.module.scss"

const withDelay = (fn, delay = 2000) => {
  const timer = setTimeout(() => {
    fn()
    clearTimeout(timer)
  }, delay)
}

export const Footer = ({ className }) => {
  const { pathname } = useLocation()
  return (
    <div className={app__footer__section}>
      <div className={app__footer}>
        <AnimatedSection>
          <div className={app__footer__logo}>
            {/* <figure> */}
            <img src={logoY} alt="logo" />
            <figcaption>GLADIATORZY</figcaption>
            <figcaption>JAVASCRIPTU</figcaption>
            {/* </figure> */}
          </div>
        </AnimatedSection>
        {/* <ul className={app__footer__list}>
          <li className={app__footer__list__item}>
            <a href="/tasks/">Katalog kursów</a>
          </li>
          <li className={app__footer__list__item}>
            <a href="/about/">O nas</a>
          </li>
          <li className={app__footer__list__item}>
            <a href="/contact/">Kontakt</a>
          </li>
        </ul> */}
        <div className={app__footer__icons}>
          <AnimatedLink
            className={app__footer__media__icon}
            to="https://www.facebook.com/localhost40310/"
          >
            <i className="fab fa-facebook-square"></i>
          </AnimatedLink>
          <AnimatedLink
            className={app__footer__media__icon}
            to="https://github.com/Przemocny/zbior-zadan-html-css-js-react"
          >
            <i className="fab fa-github"></i>
          </AnimatedLink>
        </div>
      </div>
      <div className={app__footer__copyFoot}>
        &copy; Copyright 2020 by Localhost Group sp.z.o.o{" "}
      </div>
    </div>
  )
}

export default Footer
{
  /* <React.Fragment>
			<footer className={`${className} ${app__footer}`}>
				<div className={app__footer__item}>
					<Cart className={app__footer__link} />
				</div>

				{/*
				{pathname.includes('products') && (
					<React.Fragment>
						<div className={app__footer__item__break} />
						<div className={app__footer__item}>
							<a
								onClick={() => {
									CartEmitter.emit('addToCart')
								}}
								className={app__footer__link}
							>
								<i class="fas fa-shopping-cart"></i>
								{addedToCart ? (
									<i className='icofont-check' />
								) : (
									<i className='icofont-plus' />
								)}
							</a>
						</div>
					</React.Fragment>
				)}
				*/
}
// <div className={app__footer__item__break} />

// <div className={app__footer__item}>
// 	<Link to={'/checkout/'} className={app__footer__link}>
// 		<i className='icofont-credit-card' />
// 		<i className='icofont-double-right' />
// 	</Link>
// </div>
// <div className={app__footer__item__break} />

// <div className={app__footer__item}>
// 	{/*
// 	 <Auth className={app__footer__link} />
// 	 */}
// </div>
// 	</footer>
// 	<link
// 		async
// 		defer
// 		rel='stylesheet'
// 		type='text/css'
// 		charSet='UTF-8'
// 		href='https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css'
// 	/>
// 	<link
// 		async
// 		defer
// 		rel='stylesheet'
// 		type='text/css'
// 		href='https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css'
// 	/>
// </React.Fragment> */}
