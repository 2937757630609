import React from "react"
import {WPApi} from "@api"
import {TextInput} from "@components/shared"
import is from 'is_js'


class ContactForm extends React.Component {
    state = {
        email: "",
        msg: "",
        imBusy: false,
    }
    action = () => {
        if(is.email(this.state.email)=== true ) {
            this.setState({
                imBusy: true,
            });
            WPApi.sendMail(this.state.email).then(({ message }) => {
                this.setState({
                    email: "",
                    msg: message,
                    imBusy: false,
                });
            });
        }     
    }
    render() {
        if (this.state.imBusy) {
            return (<div className="form-group">
                    <h6>Wysyłanie maila, proszę czekać</h6>
                    </div>)
        }
        if (this.state.msg != "") {
            return (
                <div className="form-group">
                    <h6>{this.state.msg}</h6>
                </div>
            )
        }
        return (
            <React.Fragment>
                <div className="form contact-form">
                    <form className="row justify-content-center">
                    <div className="col-12">
                        <div className="form-group">
                            <h6>Skontaktuj się z nami</h6>
                        </div>
                    </div>
                        <div className="col-xs-12 col-md-12">
                            <TextInput
                                type="email"
                                value={this.state.email}
                                placeholder="Wprowadź swój e-mail"
                                onChange={ev => {
                                    const { value } = ev.target
                                    this.setState({
                                        email: value,
                                    });
                                }}
                            />
                        </div>
                        <div className="col-xs-12 col-md-12">
                            <div className="form-group">
                                <button className="contact-form-button" onClick={this.action}>Wyślij</button>
                            </div>
                        </div>
                    </form>
                </div>
            </React.Fragment>
        );
    }
}

export default ContactForm
