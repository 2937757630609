import React from 'react'
// import {RadioButtons} from '@components/shared'
import {
	product__variants,
	product__variants__item,
} from '../styles/product.module.scss'

const Variants = ({variations, onVariantChange, selected}) => {
  const sorted = variations.nodes.map((el)=>{
    el.name = el.name.split("-").pop()
    return el
  })
    return (
      <div className={product__variants}>
      <h5>Warianty:</h5>
      {/*
      <RadioButtons 
        items={sorted}
        itemMapper={(el)=>({
          value: el.variationId,
          checked:el.variationId === selected,
          onChange:()=>{
            onVariantChange(el)
          },
          name: el.name
        })}
      />
 */}
      </div>
    );
  };
  

export default Variants