import React from "react"
// import { QuantityChanger } from '@common'
import {
  ResizedImage as Image,
  AnimatedLink as A,
  QuantityChanger,
} from "@components/shared"
import { CartCtx, useActions, useContextState } from "@components/contexted"

import { cart__items__wrapp } from "./Cart/cart.module.scss"

const ProductSummaryItem = ({
  product_id,
  quantity,
  image,
  name,
  value,
  slug,
}) => {
  const { removeFromCart, changeProductQty } = useActions(CartCtx, [
    "removeFromCart",
    "changeProductQty",
  ])
  return (
    <React.Fragment>
      <div>
        <A to={`/shop/products/${slug}`} className={cart__items__wrapp}>
          <h5>{name}</h5>
          <h5>{value} PLN</h5>

          <Image {...image} size={"100w"} />

          <button
            onClick={() => {
              removeFromCart({ product_id })
            }}
          >
            <i class="far fa-trash-alt"></i>
          </button>
        </A>
        <QuantityChanger
          onChange={(quantity) => {
            changeProductQty({ product_id, quantity })
          }}
          quantity={quantity}
        />
      </div>
    </React.Fragment>
  )
}

export default ProductSummaryItem
