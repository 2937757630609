import React, { useContext } from 'react'

import {
	app__flex_row,
} from './layout.module.scss'


const FlexRow = ({children })=>{
	return <div className={app__flex_row}
	>{children}</div>
}

export default FlexRow