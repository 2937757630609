
import React, {  useContext, useCallback } from 'react'
import { useLocation } from '@reach/router'
import {RelocationCtx} from './' 

const useDelayedRedirect = ({to='/', onClick=()=>null})=>{
	const { onClickLink, onChangeLocation, onClickSource } = useContext(RelocationCtx)

	const { pathname } = useLocation()
	const redirect = useCallback((ev) => {
		ev.preventDefault()

		onClickSource({
			x: ev.clientX,
			y:  ev.clientY + window.scrollY
		})

		if(pathname !== to){
			onClick && onClick()
			onChangeLocation(true)
			onClickLink(to)
			// onClickSource({
			// 	x:ev.clientX,
			// 	y:ev.clientY
			// })
		}
	},[])

	return redirect
}

export default useDelayedRedirect
