import React from "react"
import { Breadcrumbs, Reviews, Slider, Stepper } from "@components/shared"
import { FlexRow } from "@components/layout"
import {
  Description,
  Attributes,
  AddToCart,
  Tags,
  ProductAvailability,
} from "./common"
import { Layout, Parallax } from "@components/layout"

import {
  product__wrapper,
  product__inner,
  product__title,
  product__category,
  product__content,
  product__content__icon,
  product__longDescription,
  slider,
} from "./styles/product.module.scss"

const SimpleProduct = (props) => {
  const { addToCart, onGalleryClick, product, cart, page } = props
  console.log("product", product)
  return (
    <div className={product__wrapper}>
      <div className={product__inner}>
        <h6 className={product__category}>
          {product.productCategories.nodes[0].name}
        </h6>
        <Breadcrumbs
          elements={[
            {
              label: product.productCategories.nodes[0].name,
              url: `/shop/categories/${product.productCategories.nodes[0].slug}/`,
            },
            {
              label: product.name,
              url: `/shop/products/${product.slug}/`,
            },
          ]}
        />

        <div className={product__content__icon}></div>
        <div className={product__content}>
          <div>
            
            <h2 className={product__title}>{product.name}</h2>

            {product.productTags && (
              <Tags productTags={product.productTags.nodes} />
            )}

            {product.attributes && (
              <Attributes attributes={product.attributes.nodes} />
            )}
            <Description description={product.shortDescription} />
            <ProductAvailability stockQuantity={product.stockQuantity} />
            <AddToCart
              {...product}
              onAddToCart={(item) => {
                addToCart({
                  ...item,
                  image: product.image,
                })
              }}
            />
          </div>

          {product.galleryImages && (
            <Slider className={slider} items={product.galleryImages.nodes} />
          )}
        </div>

        <div className={product__longDescription}>
          {/* <Stepper> */}
          <Description title={"Opis"} description={product.description} />
          <Reviews
            title={"Opinie"}
            productId={product.productId}
            contextReviews={product.reviews.nodes}
          />
          {/* </Stepper> */}
        </div>
      </div>
    </div>
  )
}

export default SimpleProduct
