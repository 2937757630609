import React, { useState, useEffect } from 'react'
import Slider from "react-slick"
import { retrieveReviews } from './logic'
import { Link } from 'gatsby'

import { FlexRow } from '@components/layout'
import {
    reviews__item,
    reviews_item_product,
    reviews__item__author,
    reviews__item__date,
    reviews__item__stars,
    reviews__item__star__active,
    reviews__item__content
} from './random.module.scss'

const settings = {
    infinite: true,
    arrows: true,
    slidesToShow: 1,
    dotsClass: "slick-dots slick-thumb",
    slidesToScroll: 1,
    autoplay: true,
    speed: 400,
    autoplaySpeed: 5000,
    cssEase: "ease-in-out",
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }

  const SingleReviewSlide = ({ date_created, reviewer, review, rating, product_id, products }) => {
      let prods = products.filter(({productId}) => productId == product_id)
      let reviewProduct = prods[0]
    return (
		<div className={reviews__item}>
			<FlexRow justify={'space-between'}>
				<div className={reviews__item__stars}>
					<i
						className={`icofont-star ${rating > 0 &&
							reviews__item__star__active}`}
					/>
					<i
						className={`icofont-star ${rating > 1 &&
							reviews__item__star__active}`}
					/>
					<i
						className={`icofont-star ${rating > 2 &&
							reviews__item__star__active}`}
					/>
					<i
						className={`icofont-star ${rating > 3 &&
							reviews__item__star__active}`}
					/>
					<i
						className={`icofont-star ${rating > 4 &&
							reviews__item__star__active}`}
					/>
				</div>
				<h5 className={reviews__item__date}>
					{date_created.substr(0, 10)}
				</h5>
			</FlexRow>
			<h5 className={reviews__item__author}>{reviewer} o</h5>
            <h5 className={reviews_item_product}><Link 
            to={`/products/${reviewProduct.slug}/`}>{reviewProduct.name}</Link></h5>
			<h5
				className={reviews__item__content}
				dangerouslySetInnerHTML={{ __html: review }}
			/>
		</div>
	)
  }
class RandomReviews extends React.Component {
    state = {
        reviews:[],
        imBusy: true
    }

    componentDidMount(){
        retrieveReviews()
            .then((data) => {
                this.setState({
                    reviews: data,
                    imBusy: false,
                })
            })
            .catch(() => {
                this.setState({
                    imBusy: false,
                })
            })
    }

    render () {
        const { reviews, imBusy } = this.state
        const { products } = this.props
        const _reviews = reviews.filter(({rating}) => rating >= 4)
        return (
            <div className="reviews-slider">
                {_reviews.length > 0 && 
                <Slider {...settings} >
                {_reviews.map((el, k) => {
                    return (
                        <div key={k}>
                            <SingleReviewSlide {...el} products={products}/>
                        </div>
                    )
                })}
                </Slider>
                }
            </div>
        )
    }
}

export default RandomReviews