/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import favicon from "./gladiators_logo.png"

const siteBasic = {
  title: "",
  description: "",
  lang: "",
}

const seoBasic = {
  title: "Page",
  opengraphTitle: "",
  metaKeywords: "",
  metaDesc: "",
  metaRobotsNofollow: "",
  metaRobotsNoindex: "",
  opengraphDescription: "",
  opengraphImage: null,
}

function Head({ siteMetaData = {}, seo = {}, ogType = "website", location }) {
  return (
    <Helmet
      htmlAttributes={{
        lang: siteMetaData.language || siteBasic.lang,
      }}
      title={`${seo.title}`}
      meta={[
        {
          name: `description`,
          content: seo.opengraphDescription || siteMetaData.description,
        },
        {
          name: `keywords`,
          content: seo.focuskw || "",
        },
        {
          property: `og:title`,
          content: seo.title || siteMetaData.title || siteBasic.description,
        },
        {
          property: `og:description`,
          content:
            seo.opengraphDescription ||
            siteMetaData.description ||
            siteBasic.description,
        },
        {
          property: `og:image`,
          content: seo.opengraphImage ? seo.opengraphImage.sourceUrl : "",
        },
        {
          property: `og:image:alt`,
          content: seo.title,
        },
        {
          property: `og:type`,
          content: ogType,
        },
        {
          property: `og:url`,
          content: location.href,
        },
      ]}
    >
      {/* favicon */}
      <link rel="icon" href={favicon} />

      <link rel="preconnect dns-prefetch" href="https://cdnjs.cloudflare.com" />
      <link rel="preconnect dns-prefetch" href="https://fonts.googleapis.com" />

      <link
        async
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.4.1/css/bootstrap-grid.min.css"
      />
      <link
        async
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.4.1/css/bootstrap-reboot.min.css"
      />
      <link
        async
        rel="preload"
        href="https://fonts.googleapis.com/css?family=Poppins:300,400,600,700&display=swap"
        rel="stylesheet"
      />
      <link
        async
        rel="preload"
        href="https://fonts.googleapis.com/css2?family=Spartan:wght@300;400;500;600;700;800;900&display=swap"
        rel="stylesheet"
      />
      <script
        async
        defer
        type="text/javascript"
        src="https://kit.fontawesome.com/0e40483b21.js"
        crossorigin="anonymous"
      ></script>

      <script async defer type="text/javascript">{`
			  window.replainSettings = { id: 'c230a18c-f3d7-491c-b74f-d00f4e662bea' };
			  (function(u){var s=document.createElement('script');s.type='text/javascript';s.async=true;s.src=u;
			  var x=document.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);
			  })('https://widget.replain.cc/dist/client.js');
		  `}</script>

<script async type="text/javascript">
    {`(function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:1956375,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv='); `}
</script>
      <script
        async
        defer
        type="text/javascript"
        src="https://apis.google.com/js/api.js"
      ></script>
    </Helmet>
  )
}

export default Head
