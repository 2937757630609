import React, { useRef, useContext, useEffect, useCallback } from "react"
import { Layout } from "@components/layout"
import { Stepper } from "@components/shared"
import { CartCtx, CartProvider } from "@components/contexted"

import { OrderAndDeliveryStep, CartSummaryStep, createOrder } from "./common"

const BeforeCustomerData = (props) => {
  const stepper = useRef({ setIndex: () => {} })
  const { state } = useContext(CartCtx)

  const { pageContext, cart, customer } = props
  const { page } = pageContext
  const { imBusy } = cart
  return (
    <Layout {...page} imBusy={imBusy}>
      <div className="checkout">
        <Stepper ref={stepper} withScroll={true}>
          <CartSummaryStep
            {...props}
            {...{ stepper }}
            title={"1. Podsumowanie"}
          />
          <OrderAndDeliveryStep
            {...props}
            cart={state}
            createOrder={createOrder}
            {...{ stepper }}
            title={"2. Dane osobowe"}
          />
        </Stepper>
        {/* <AddToCart /> */}
      </div>
    </Layout>
  )
}

export default BeforeCustomerData

// const AddToCart = () => {
//   const { dispatch, state } = useContext(CartCtx)
//   useEffect(() => {
//     console.log("AddToCart state", state)
//   }, [state])
//   const action = useCallback(() => {
//     dispatch({
//       type: "addToCart",
//       payload: {
//         product_id: 0,
//         quantity: 1,
//         sale: false,
//         regularPrice: 100,
//         price: 100,
//         value: 100,
//         regularValue: 100,
//       },
//     })
//   }, [])

//   return (
//     <React.Fragment>
//       <button onClick={action}>test</button>
//       {JSON.stringify(state)}
//     </React.Fragment>
//   )
// }
