import React, { useState, useCallback } from 'react'
import { useSpring, animated } from 'react-spring/'
import { AnimatedLink } from '@components/shared'

import {
	header__buttons,
	header__buttons__btn,
	header__buttons__btn__alt,
} from './header.module.scss'

const NavButtons = () => {
	return (
		<div className={header__buttons}>
			<AnimatedLink
				onClick={()=>{console.log('asd')}}
				className={[
					header__buttons__btn,
				].join(' ')}
				to={'/gladiators/'}
			>
				Zostań Gladiatorem
			</AnimatedLink>
			<AnimatedLink
				className={[
					header__buttons__btn,
					header__buttons__btn__alt,
				].join(' ')}
				to={'/tasks/'}
			>
				Zobacz zadania
			</AnimatedLink>
		</div>
	)
}

export default NavButtons