import React from "react"
import { Modal } from "@components/shared"
import { AnimatedButton } from "@components/shared"

import {
  cookies__wrapper,
  cookies__content,
  cookies__content__header,
  cookies__content__text,
  cookies__content__buttons,
  cookies__content__buttons__btn,
} from "./cookies.module.scss"

class CookiesModal extends React.Component {
  state = {
    toggled: false,
  }

  componentDidMount() {
    if (localStorage.getItem("cookiesAccepted") !== "true") {
      this.setState({
        toggled: true,
      })
    }
  }

  render() {
    if (this.state.toggled === true) {
      return (
        <div className={cookies__wrapper}>
          <div className={cookies__content}>
            <h3 className={cookies__content__header}>
              POLITYKA PLIKÓW COOKIES
            </h3>
            <h5 className={cookies__content__text}>
              W ramach naszej witryny stosujemy pliki cookies w celu świadczenia
              Państwu usług na najwyższym poziomie, w tym w sposób dostosowany
              do indywidualnych potrzeb. Korzystanie z witryny bez zmiany
              ustawień dotyczących cookies oznacza, że będą one zamieszczane w
              Państwa urządzeniu końcowym. Możecie Państwo dokonać w każdym
              czasie zmiany ustawień dotyczących cookies.
            </h5>
            <h5 className={cookies__content__text}>
              Więcej informacji{" "}
              <a target="_blank" href="/regulaminy">
                zapoznaj się z polityką plików cookies
              </a>
            </h5>
            <div className={cookies__content__buttons}>
              <AnimatedButton
                onClick={() => {
                  this.setState({
                    toggled: false,
                  })
                  localStorage.setItem("cookiesAccepted", "true")
                }}
                className={[cookies__content__buttons__btn].join(" ")}
                to={"/"}
              >
                Akceptuję
              </AnimatedButton>
            </div>
          </div>
        </div>
      )
    }
    return null
  }
}

export default CookiesModal
