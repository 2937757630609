import React from "react"
import { AnimatedLink } from "@components/shared"

import { cart } from "../cart.module.scss"

const EmptyCart = ({ className }) => {
  return (
    <div className={cart}>
      <AnimatedLink className={className} to="/shop/checkout/">
        <i class="fas fa-shopping-cart"></i>
      </AnimatedLink>
    </div>
  )
}

export default EmptyCart
