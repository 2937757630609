import React from "react"
import failed from "./img/DENIED.png"

import {
  summary__stats,
  summary__stats__links,
  summary__stats__links__single,
} from "./styles/summary.module.scss"
const PurchaseFailed = ({ orderId, msg }) => {
  return (
    <div className={summary__stats}>
      <h3>
        Status: <strong>{msg}</strong>
      </h3>
      <h5>W przypadku problemu ze zrealizowaniem zakupów prosimy o kontakt:</h5>
      <div className={summary__stats__links}>
        <div className={summary__stats__links__single}>
          <i class="fab fa-facebook-square"></i>
          <h5>
            <a target="_blank" href="https://www.facebook.com/localhost40310">
              {" "}
              Localhost Group
            </a>
          </h5>
        </div>
        <div className={summary__stats__links__single}>
          <i class="far fa-envelope"></i>
          <h5>
            <a href="mailto:contact@localhost-group.com">
              {" "}
              contact@localhost-group.com
            </a>
          </h5>
        </div>
        <div className={summary__stats__links__single}>
          <i class="fas fa-mobile-alt"></i>
          <h5>
            <a href="tel:+48535000959">(+48) 535 000 959</a>
          </h5>
        </div>
      </div>
      <img src={failed} alt="payment-failed" />
    </div>
  )
  // return ['tresc błędu', 'nr zamówienia', 'link do platnosci', 'contact ze sklepem'].join(' | ')
}
export default PurchaseFailed
