import React, { useRef, useContext } from "react"
import {
  Modal,
  TextInput,
  AnimatedLink,
  AnimatedButton,
} from "@components/shared"
import { withHtml } from "@components/logic"
import { SearchCtx, useActions, useContextState } from "@components/contexted"

import product from "./img/shopping-cart (1).png"
import post from "./img/speech-bubble.png"
import page from "./img/unstructured-information.png"

import {
  search,
  search__form,
  search__form__icon,
  search__list,
  search__item,
  search__item__link,
  search__noelements,
  search__wrapp,
  search__wrapp__icon,
} from "./search.module.scss"

const urlRE = new RegExp(".(gif|jpe?g|tiff|png|webp|bmp)", "i")

const replaceUrlWithSize = (url, size = 150) => {
  const [ext] = urlRE.exec(url)
  return url.replace(ext, `-${size}x${size}${ext}`)
}

const H6 = withHtml("h6")

const SearchListElem = ({ title, slug, type, onClick }) => {
  if (type === "product") {
    return (
      <AnimatedLink to={`/shop/products/${slug}/`} className={search__item} onClick={onClick}>
        <div className={search__item__link}>
          <img src={product} alt="" />
          <H6>{title}</H6>
        </div>
      </AnimatedLink>
    )
  }
  if (type === "post") {
    return (
      <AnimatedLink to={`/blog/${slug}/`} className={search__item} onClick={onClick}>
        <div className={search__item__link}>
          <img src={post} alt="" />
          <H6>{title}</H6>
        </div>
      </AnimatedLink>
    )
  }
  return (
    <AnimatedLink to={`/${slug}/`} className={search__item} onClick={onClick}>
      <div className={search__item__link}>
        <img src={page} alt="" />
        <H6>{title}</H6>
      </div>
    </AnimatedLink>
  )
}

const Search = ({ className }) => {
  const modal = useRef()
  const { setPhrase } = useActions(SearchCtx, "setPhrase")
  const { all, filtered, phrase, imBusy } = useContextState(SearchCtx, [
    "all",
    "filtered",
    "phrase",
    "imBusy",
  ])

  const placeholder = all.length === 0 ? "..." : <i class="fas fa-search"></i>

  return (
    <div className={search}>
      <AnimatedButton
        className={className}
        disabled={imBusy}
        onClick={() => {
          modal.current.toggle()
        }}
      >
        {placeholder}
      </AnimatedButton>
      <Modal ref={modal} title={"Znajdź to co Cię interesuje"}>
        <div className={search__wrapp}>
          <div className={search__wrapp__icon}></div>
          <div className={search__form}>
            <form
              onSubmit={(ev) => {
                ev.preventDefault()
              }}
            >
              <TextInput
                autofocus={true}
                value={phrase}
                onChange={(ev) => {
                  setPhrase(ev.target.value)
                }}
                placeholder={"Wyszukaj na stronie"}
              />
              <div className={search__form__icon}>
                <i class="fas fa-search"></i>
              </div>
            </form>
          </div>
          <ul className={search__list}>
            {filtered.length === 0 && (
              <div className={search__noelements}>Brak szukanych elementów</div>
            )}
            {filtered.map((el, k) => {
              return (
                <SearchListElem
                  {...el}
                  key={k}
                  onClick={() => {
                    setPhrase("")
                  }}
                />
              )
            })}
          </ul>
        </div>
      </Modal>
    </div>
  )
}

export default Search
