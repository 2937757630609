import React, { useRef } from "react"
import { connect } from "react-redux"
import { Modal, Stepper } from "@components/shared"
import CustomerForm from "./CustomerForm"
import LoginForm from "./LoginForm"
import { customerActions, cartActions } from "@stores"
import {
  createCustomer,
  getCustomers,
  loginCustomer,
  updateCustomer,
} from "./logic"

import "./auth.scss"
import { get } from "https"
// przerobić na css-module aby zmniejszać kod css
// do imienia dodać nazwę firmy oraz znacznik B2B / HORECA

const localStorageKey = "loginDetails"

class Auth extends React.Component {
  state = {
    isLogged: localStorage.getItem("isLoggedIn"),
    message: "",
  }
  modal = React.createRef()
  stepper = React.createRef({ setIndex: () => {} })

  render() {
    const { store, changeValue, className } = this.props

    let loginCredentials =
      JSON.parse(localStorage.getItem(localStorageKey)) || []

    return (
      <div className="auth">
        <div className="auth-toggler">
          <button
            className={className}
            onClick={() => {
              this.modal.current.toggle()
            }}
          >
            <i className="icofont-user" />
          </button>
        </div>
        <Modal title={""} ref={this.modal}>
          <div className="auth-modal">
            {this.state.isLogged != "true" ? (
              <div>
                <Stepper ref={this.stepper} withScroll={true}>
                  <div className="col-12" title={"Zaloguj się"}>
                    <LoginForm
                      store={store}
                      changeValue={changeValue}
                      onLogin={() => {
                        loginCustomer(
                          store.customer.email,
                          store.customer.password
                        )
                          .then((data) => {
                            localStorage.setItem(
                              localStorageKey,
                              JSON.stringify(data)
                            )
                            localStorage.setItem("isLoggedIn", "true")
                            this.setState({
                              isLogged: "true",
                            })
                          })
                          .catch(({ data }) => {})
                      }}
                    />
                  </div>
                  <div className="col-12" title={"Załóż nowe konto"}>
                    <CustomerForm
                      store={store}
                      changeValue={changeValue}
                      onCreateCustomer={() => {
                        createCustomer(store.customer)
                          .then((data) => {
                            console.log(data)
                            this.setState({
                              message:
                                "Konto założone pomyślnie. Możesz się teraz zalogować.",
                            })
                          })
                          .catch(({ data }) => {
                            console.log(data)
                          })
                      }}
                    />
                    <h5>{this.state.message}</h5>
                  </div>
                </Stepper>
              </div>
            ) : (
              <CustomerForm
                store={store}
                changeValue={changeValue}
                update={true}
                onCustomerUpdate={() => {
                  updateCustomer(store.customer, loginCredentials.id)
                    .then((data) => {
                      console.log(data)
                      this.setState({
                        message: "Twoje dane zostały pomyślnie zaktualizowane.",
                      })
                    })
                    .catch(({ data }) => {
                      console.log(data)
                    })
                }}
              />
            )}
          </div>
          {/* desktop */}
          <div className="auth-footer"></div>
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return { store: state.Customer }
}

const mapActionToDispatch = { ...customerActions }

export default connect(mapStateToProps, mapActionToDispatch)(Auth)
