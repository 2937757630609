import React from "react"
import { Layout } from "@components/layout"
import { AnimatedLink } from "@components/shared"

const NoItemsInCart = (props) => {
  const { pageContext, cart } = props
  const { page } = pageContext
  const { imBusy } = cart
  return (
    <Layout {...page} imBusy={imBusy}>
      <div className="checkout" style={{ margin: 50 }}>
        <h1 style={{ textAlign: "center", padding: 20 }}>
          Brak rzeczy w koszyku
        </h1>
        <h2 style={{ textAlign: "center", padding: 20, color: "#19212d" }}>
          Ostatnio oglądane:
        </h2>
        <div>
          <h1 style={{ textAlign: "center", padding: 20, color: "red" }}>
            <AnimatedLink to={"/shop/"}>Do biblioteki!</AnimatedLink>
          </h1>
        </div>
      </div>
    </Layout>
  )
}

export default NoItemsInCart
