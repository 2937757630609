import React from "react"
import { Parallax } from "@components/layout"
import { AnimatedLink } from "@components/shared"
import {
  wrapper,
  double__cta,
  double__cta__wave,
  double__cta__button,
  double__cta__button_alt,
} from "./styles/double.module.scss"

import {
  arrows,
  arrows__left,
  arrows__right,
} from "../../theme/DirectionArrows/arrows.module.scss"

export const DoubleCTASection = ({ label1, link1, label2, link2 }) => {
  return (
    <div className={wrapper}>
      <div className={arrows}>
        <AnimatedLink className={arrows__left} to="/gladiators">
          <a href={link1}>{label1}</a>
        </AnimatedLink>

        <AnimatedLink className={arrows__right} to="/tasks">
          <a href={link2}>{label2}</a>
        </AnimatedLink>
      </div>
      {/* <div className={double__cta__wave}>
        <svg viewBox="0 0 1200 300">
          <path
            d="M1200,300s4.35-313-530.32-238.81S0,0,0,0H1200Z"
            transform="translate(0)"
            fill="#19212d"
          />
        </svg>
      </div> */}
    </div>
  )
}

export default DoubleCTASection
