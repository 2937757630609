import React, { useState, useCallback } from 'react'
import { useSpring } from 'react-spring/'
import { InteractWithUser } from '@components/logic'

const animationStates = {
    idle:1,
    hover:0.95,
    clicking:0.9,
    clicked:1,
}

const AnimatedButton = ({className, onClick=()=>null, children}) => {
	const [ mode, setter ] = useState(false)
	const { scale } = useSpring({
		config: { mass:0.2, tension: 270, friction: 6 },
		scale: animationStates[mode] || 1,
	})

	const animationSwitch = useCallback(setter, [])

    return <InteractWithUser 
        onChangePhase={animationSwitch}
        className={className}
        style={{
            transform: scale.interpolate((s) => `scale3d(${s}, ${s}, ${s})`)
        }}
    >
        <a
            onClick={onClick}
        >
            {children}
        </a>
    </InteractWithUser>
}

export default AnimatedButton
