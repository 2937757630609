import React, { useContext } from 'react'

import {
	app__flex_col
} from './layout.module.scss'


const FlexCol = ({children})=>{
	return <div className={app__flex_col}>{children}</div>
}

export default FlexCol